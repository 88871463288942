<template>
  <div>
    <b-collapse :open="false" v-if="!sent">
      <template #trigger>
        <b-button
          label="Add Comment"
          size="is-normal"
          icon-left="comment"
          type="is-info" />
      </template>
      <div class="notification">
        <div>
          <h4 class="title is-4">Leave a comment</h4>

          <b-notification
            v-if="errors.length"
            type="is-danger"
            aria-close-label="Close"
            role="alert"
          >
            <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
          </b-notification>

          <form @submit="handleSubmit">
            <b-field label="Your comment">
              <editor
                v-model="formData.text"
                api-key="llkyn54j9cm6dvjfak7eywf59bxnopcwyoq8fetp590v7ftf"
                :init="{
                     height: 200,
                     menubar: false,
                     plugins: [
                       'autolink lists link code paste codesample preview'
                     ],
                     toolbar: 'preview | undo redo | bold italic | codesample | bullist numlist',
                     default_link_target: '_blank',
                     extended_valid_elements: 'a[href|target=_blank]'
                   }"
              />
            </b-field>

            <b-field label="Attachments">
              <b-upload
                v-model="dropFiles"
                accept="image/*"
                multiple
                drag-drop
                expanded
              >
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"></b-icon>
                    </p>
                    <p>Drop your files here or click to upload</p>
                  </div>
                </section>
              </b-upload>
            </b-field>

            <div class="tags">
                <span
                  v-for="(file, index) in dropFiles"
                  :key="index"
                  class="tag is-primary"
                >
                  {{file.name}}
                  <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
                </span>
            </div>

            <b-button type="is-success" native-type="submit">Submit</b-button>
          </form>

          <b-loading :is-full-page="false" v-model="loadingForm"></b-loading>
        </div>
      </div>
    </b-collapse>
    <div v-else class="notification has-text-centered is-success">
      <div>
        <h3 class="title is-3">Awesome!</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import { Forum } from '@/services/api';

export default {
  name: 'comment-form',
  components: { Editor },
  data() {
    return {
      errors: [],
      dropFiles: [],
      formData: {
        subscribe: true,
        text: null,
      },
      loadingForm: false,
      sent: false,
    };
  },
  computed: {
    isBugsSelected() {
      return this.selectedForum.slug === 'bugs';
    },
    ...mapState('forum', ['selectedForum', 'selectedTopic']),
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      Forum.createComment(
        this.selectedForum.slug,
        this.selectedTopic.id,
        this.formData.text,
        this.formData.subscribe,
        this.dropFiles,
      )
        .then(() => {
          this.sent = true;
          this.$store.dispatch('forum/loadComments', 1);
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
