<template>
  <section class="section">
    <b-loading :is-full-page="false" v-model="showLoading"></b-loading>

    <div v-if="selectedForum && selectedTopic">
      <b-message v-if="!selectedTopic.approved" type="is-danger" has-icon>
        This topic needs APPROVAL!
      </b-message>

      <h5 class="title is-4">{{ selectedTopic.title }}</h5>
      <h6 class="subtitle is-6">
        Posted by: <strong>{{ selectedTopic.user.name }}</strong>
        on {{ selectedTopic.created_at | dateStandard }}
        in <router-link :to="`forums/${selectedForum.slug}`"><strong>{{ selectedForum.title }}</strong></router-link>
      </h6>

      <form @submit="updateTopic">
        <b-field label="Topic text">
          <editor
            v-model="selectedTopic.text"
            api-key="llkyn54j9cm6dvjfak7eywf59bxnopcwyoq8fetp590v7ftf"
            :init="{
               height: 400,
               menubar: false,
               plugins: [
                 'advlist autolink lists link searchreplace visualblocks table paste code codesample help preview'
               ],
               toolbar:
                 'preview | undo redo | formatselect | bold italic backcolor | codesample | \
                 alignleft aligncenter alignright alignjustify | table | \
                 bullist numlist outdent indent | removeformat | help',
               extended_valid_elements: 'a[href|target=_blank]'
             }"
          />
        </b-field>

        <div class="columns">
          <div class="column is-4">
            <b-field label="Status">
              <b-select placeholder="Select a status" v-model="selectedTopic.status">
                <option value="Open">Open</option>
                <option value="To do">To do</option>
                <option value="In progress">In progress</option>
                <option value="Fixed">Fixed</option>
                <option value="Won't do">Won't do</option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Status Text">
              <b-input v-model="selectedTopic.status_text"></b-input>
            </b-field>
          </div>
        </div>

        <div class="columns">
          <div class="column is-4">
            <b-field label="Comments Lock?">
              <b-switch
                true-value="1"
                v-model="selectedTopic.closed"
                passive-type="is-success"
                type="is-warning">
                {{ selectedTopic.closed ? "Locked" : "Unlocked" }}
              </b-switch>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Closed Reason">
              <b-input v-model="selectedTopic.closed_reason"></b-input>
            </b-field>
          </div>
        </div>

          <b-switch
            true-value="1"
            v-model="selectedTopic.approved"
            passive-type="is-danger"
            type="is-success">
            {{ selectedTopic.approved ? "Approved" : "Unapproved" }}
          </b-switch>

          <b-switch
            true-value="1"
            v-model="selectedTopic.pinned"
            type="is-success">
            {{ selectedTopic.pinned ? "Pinned" : "Not pinned" }}
          </b-switch>

        <div class="columns mt-2">
          <div class="column">
            <b-button type="is-success" native-type="submit" icon-left="check">Update</b-button>
          </div>

          <div class="column has-text-right">
            <b-button
              @click="deleteTopic"
              icon-left="trash"
              label="Delete"
              type="is-danger" />
          </div>
        </div>
      </form>

      <photo-list
        v-if="selectedTopic.photos.length"
        type="topics"
        :id="selectedTopic.id"
        :photos="selectedTopic.photos"
        class="mt-4"
      ></photo-list>

      <hr class="mt-6 mb-6" />

      <b-message v-if="selectedTopic.closed" type="is-warning" has-icon>
        The comments are LOCKED for this topic!
      </b-message>

      <comment-form class="mt-4"></comment-form>

      <comment-list :comments="comments.data"></comment-list>

      <b-pagination
        class="mt-4"
        v-model="comments.current_page"
        :total="comments.total"
        :per-page="comments.per_page"
        range-before="3"
        range-after="3"
        order="is-centered"
        icon-pack="fas"
        @change="handlePageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">
      </b-pagination>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import CommentList from '@/components/Forum/CommentList.vue';
import CommentForm from '@/components/Forum/CommentForm.vue';
import PhotoList from '@/components/Forum/PhotoList.vue';

export default {
  name: 'TopicDetails',
  components: {
    CommentForm,
    CommentList,
    PhotoList,
    Editor,
  },
  props: {
    forum: String,
    topic: String,
  },
  computed: {
    ...mapState('forum', ['loadingTopic', 'updatingTopic', 'selectedTopic', 'selectedForum', 'comments']),
    showLoading() {
      return this.loadingTopic || this.updatingTopic;
    },
  },
  mounted() {
    this.loadTopic(this.forum, this.topic);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadTopic(to.params.forum, to.params.topic);
    next();
  },
  methods: {
    async loadTopic(forumSlug, topicId) {
      await this.$store.dispatch('forum/loadForum', forumSlug);
      await this.$store.dispatch('forum/loadTopic', topicId);

      this.$store.dispatch('forum/loadComments', 1);
    },
    handlePageChange(pageNum) {
      this.$store.dispatch('forum/loadComments', pageNum);
    },
    approveTopic() {
      this.$store.dispatch('forum/approveTopic', this.topic);
    },
    unapproveTopic() {
      this.$store.dispatch('forum/unapproveTopic', this.topic);
    },
    deleteTopic() {
      this.$buefy.dialog.confirm({
        title: 'Deleting topic',
        message: 'Are you sure you want to <b>delete</b> this topic? This action cannot be undone.',
        confirmText: 'Delete Topic',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('forum/deleteTopic', this.topic)
            .then(() => {
              this.$buefy.toast.open('Topic deleted!');
              this.$router.push({ path: `/forums/${this.forum}` });
            });
        },
      });
    },
    updateTopic(event) {
      event.preventDefault();

      this.$store.dispatch('forum/updateTopic', this.selectedTopic);
    },
  },
};
</script>

<style scoped lang="scss">
.topic-content {
  white-space: pre-wrap;
}
</style>
