var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.sent)?_c('b-collapse',{attrs:{"open":false},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{attrs:{"label":"Add Comment","size":"is-normal","icon-left":"comment","type":"is-info"}})]},proxy:true}],null,false,3788802985)},[_c('div',{staticClass:"notification"},[_c('div',[_c('h4',{staticClass:"title is-4"},[_vm._v("Leave a comment")]),(_vm.errors.length)?_c('b-notification',{attrs:{"type":"is-danger","aria-close-label":"Close","role":"alert"}},_vm._l((_vm.errors),function(error,index){return _c('div',{key:index},[_vm._v(_vm._s(error))])}),0):_vm._e(),_c('form',{on:{"submit":_vm.handleSubmit}},[_c('b-field',{attrs:{"label":"Your comment"}},[_c('editor',{attrs:{"api-key":"llkyn54j9cm6dvjfak7eywf59bxnopcwyoq8fetp590v7ftf","init":{
                   height: 200,
                   menubar: false,
                   plugins: [
                     'autolink lists link code paste codesample preview'
                   ],
                   toolbar: 'preview | undo redo | bold italic | codesample | bullist numlist',
                   default_link_target: '_blank',
                   extended_valid_elements: 'a[href|target=_blank]'
                 }},model:{value:(_vm.formData.text),callback:function ($$v) {_vm.$set(_vm.formData, "text", $$v)},expression:"formData.text"}})],1),_c('b-field',{attrs:{"label":"Attachments"}},[_c('b-upload',{attrs:{"accept":"image/*","multiple":"","drag-drop":"","expanded":""},model:{value:(_vm.dropFiles),callback:function ($$v) {_vm.dropFiles=$$v},expression:"dropFiles"}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v("Drop your files here or click to upload")])])])])],1),_c('div',{staticClass:"tags"},_vm._l((_vm.dropFiles),function(file,index){return _c('span',{key:index,staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(file.name)+" "),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDropFile(index)}}})])}),0),_c('b-button',{attrs:{"type":"is-success","native-type":"submit"}},[_vm._v("Submit")])],1),_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loadingForm),callback:function ($$v) {_vm.loadingForm=$$v},expression:"loadingForm"}})],1)])]):_c('div',{staticClass:"notification has-text-centered is-success"},[_c('div',[_c('h3',{staticClass:"title is-3"},[_vm._v("Awesome!")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }